// src/components/Slider.js
import React from "react";
import Slider from "react-slick";
import { motion } from "framer-motion";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ImageSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Slider {...settings}>
      <div>
        <motion.img
          src="/image1.jpg" // Ensure image1.jpg is in the public folder
          alt="Image 1"
          className="slider-image" // Class for styling
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        />
        <h3 className="slider-caption">Our Innovative Solutions</h3>
      </div>
      <div>
        <motion.img
          src="/image2.jpg" // Ensure image2.jpg is in the public folder
          alt="Image 2"
          className="slider-image" // Class for styling
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        />
        <h3 className="slider-caption">Quality Service Guaranteed</h3>
      </div>
      <div>
        <motion.img
          src="/image3.jpg" // Ensure image3.jpg is in the public folder
          alt="Image 3"
          className="slider-image" // Class for styling
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        />
        <h3 className="slider-caption">Creative Designs</h3>
      </div>
    </Slider>
  );
};

export default ImageSlider;
