import React, { useState } from "react";
import emailjs from "emailjs-com";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    contactNumber: "",
    email: "",
    purpose: "quotation",
    message: "",
    whatsappNumber: "",
    isWhatsAppDifferent: false,
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        "service_j4rlasj",
        "template_f2pntl7",
        formData,
        "8BUWbDkRTdlxlo95K"
      )
      .then((response) => {
        console.log("Success!", response.status, response.text);
        alert("Message sent successfully!");
        setFormData({
          name: "",
          contactNumber: "",
          email: "",
          purpose: "quotation",
          message: "",
          whatsappNumber: "",
          isWhatsAppDifferent: false,
        });
      })
      .catch((err) => {
        console.error("Failed to send message:", err);
        alert("Failed to send message. Please try again.");
      });
  };

  return (
    <div className="contact-section">
      {/* Contact Form */}
      <form onSubmit={handleSubmit} className="contact-form">
        <label>Name</label>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          required
        />

        <label>Contact Number</label>
        <input
          type="text"
          name="contactNumber"
          value={formData.contactNumber}
          onChange={handleInputChange}
          required
        />

        <label>Email Address</label>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          required
        />

        <label>Purpose of Contact</label>
        <select
          name="purpose"
          value={formData.purpose}
          onChange={handleInputChange}
        >
          <option value="quotation">Quotation</option>
          <option value="inquiry">Inquiry</option>
          <option value="complaint">Complaint</option>
          <option value="other">Other</option>
        </select>

        <label>
          <input
            type="checkbox"
            name="isWhatsAppDifferent"
            checked={formData.isWhatsAppDifferent}
            onChange={handleInputChange}
          />
          Different WhatsApp Number
        </label>

        {formData.isWhatsAppDifferent && (
          <>
            <label>WhatsApp Number</label>
            <input
              type="text"
              name="whatsappNumber"
              value={formData.whatsappNumber}
              onChange={handleInputChange}
              required
            />
          </>
        )}

        <label>Message</label>
        <textarea
          name="message"
          value={formData.message}
          onChange={handleInputChange}
          required
        />

        <button type="submit">Send</button>
      </form>

      {/* Company Info Section */}
      <div className="contact-details">
        <h3>Our Location</h3>
        <iframe
          title="Google Map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3960.5148638150936!2d79.85979847448301!3d6.948429618080547!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae258e4dbcfa82b%3A0x8629dec49303324f!2s29%20Kotahena%20St%2C%20Colombo%2001300!5e0!3m2!1sen!2slk!4v1730319347516!5m2!1sen!2slk"
          width="100%"
          height="200"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
        <h3>Opening Hours</h3>
        <p>Mon - Fri: 9:00 AM - 3:00 PM</p>
        {/* <p>Sat & Sun: 10:00 AM - 4:00 PM</p> */}
        <p>Sat & Sun: Closed</p>

        <h3>Contact Information</h3>
        <p>Phone: +94 71 0 1111 01</p>
        <p>Email: info@techcubeisland.com</p>
      </div>
    </div>
  );
};

export default ContactForm;
