import React from "react";
import whatsappIcon from "./whatsapp.png"; // Update the path to the downloaded icon

function WhatsAppButton() {
  const whatsappNumber = "+94710111101";
  const message = "Hello! I have an inquiry.";
  const whatsappLink = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
    message
  )}`;

  return (
    <a
      href={whatsappLink}
      target="_blank"
      rel="noopener noreferrer"
      style={{
        position: "fixed",
        bottom: "20px",
        right: "20px",
        padding: "10px",
        borderRadius: "50%",
        backgroundColor: "transparent",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        zIndex: 1000,
      }}
    >
      <img
        src={whatsappIcon}
        alt="WhatsApp"
        style={{
          width: "75px", // Adjust size as needed
          height: "auto",
        }}
      />
    </a>
  );
}

export default WhatsAppButton;
