// src/App.js
import React, { useState } from "react";
import "./styles/App.css";
import ImageSlider from "./components/Slider";
import ContactForm from "./components/ContactForm";
import Footer from "./components/Footer";
import { FaBars, FaTimes } from "react-icons/fa";
import WhatsAppButton from "./components/WhatsappButton";
// import Navbar from "./components/Navbar";

const App = () => {
  const scrollToSection = (event) => {
    event.preventDefault(); // Prevent the default anchor click behavior
    const targetId = event.currentTarget.getAttribute("href"); // Get the target section ID
    const targetSection = document.querySelector(targetId); // Select the target section

    if (targetSection) {
      const offsetTop =
        targetSection.getBoundingClientRect().top + window.pageYOffset;
      const offsetAmount = 40; // Adjust this value to control how far above the target section you want to stop
      window.scrollTo({
        top: offsetTop - offsetAmount,
        behavior: "smooth",
      });
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  // Toggle the menu open/close
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <WhatsAppButton/>
      <header className="header">
        <nav className="navbar">
          <div className="navbar-logo">
            <a href="#home" onClick={scrollToSection}>
              <img src="logo.png" alt="Logo" />
            </a>
          </div>

          {/* Hamburger icon for small screens */}
          <button className="menu-icon" onClick={toggleMenu}>
            {isOpen ? <FaTimes /> : <FaBars />}
          </button>

          {/* Links with conditional class for showing/hiding on smaller screens */}
          <ul className={`navbar-links ${isOpen ? "active" : ""}`}>
            <li>
              <a href="#home" onClick={scrollToSection}>
                Home
              </a>
            </li>
            <li>
              <a href="#services" onClick={scrollToSection}>
                Our Services
              </a>
            </li>
            <li>
              <a href="#contact" onClick={scrollToSection}>
                Contact Us
              </a>
            </li>
          </ul>
        </nav>
      </header>

      <section className="slider" id="home">
        <ImageSlider />
      </section>

      <section className="services" id="services">
        <h2>Our Services</h2>
        <div className="services-container">
          <div className="service-card">
            <h3>Illustrations</h3>
            <p>High-quality custom illustrations tailored to your needs.</p>
          </div>
          <div className="service-card">
            <h3>Social Media Marketing</h3>
            <p>Boost your brand's visibility and engagement on social media.</p>
          </div>
          <div className="service-card">
            <h3>Web Development</h3>
            <p>
              Responsive and user-friendly websites designed for your audience.
            </p>
          </div>
          <div className="service-card">
            <h3>Mobile Development</h3>
            <p>Robust mobile applications for all platforms.</p>
          </div>
          <div className="service-card">
            <h3>IT Solutions</h3>
            <p>Comprehensive IT services to meet your business needs.</p>
          </div>
          <div className="service-card">
            <h3>Web Hosting and Company Emails</h3>
            <p>Reliable hosting solutions with professional email services.</p>
          </div>
        </div>
      </section>
      <section id="contact">
        <ContactForm />
      </section>
      <div className="App">
        {/* Your main content */}
        <Footer />
      </div>
    </div>
  );
};

export default App;
